import React, { useEffect, useMemo, useState, useContext } from 'react';
import { isEmpty } from 'lodash';
import Icon from '../../atoms/Icon/Icon';
import Button from '../../atoms/Button/Button';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import Divider from '../../atoms/Divider/Divider';
import CommentCard from '../../molecules/CommentCard/CommentCard';
import AuthContext from '../../../context/AuthProvider';
import { wpApi } from '../../../helpers/wordpress';
import {
  setStorage,
  getStorage,
  removeStorage
} from '../../../helpers/general';
import { validateEmail } from '../../../helpers/general';
import styles from './CommentList.module.css';

const validForm = data => {
  if (!data.content) {
    return 'Comment is required.';
  }
  if (!data.author_name) {
    return 'Name is required.';
  }
  if (!data.author_email) {
    return 'Email is required.';
  }
  if (!validateEmail(data.author_email)) {
    return 'Invalid email address.';
  }
};

const CommentList = ({ comments, postDatabaseId }) => {
  const [showAll, setShowAll] = useState(false);
  const [isShowForm, setIsShowForm] = useState(false);
  const authCtx = useContext(AuthContext);
  const [formData, setFormData] = useState({});
  const [msg, setMessage] = useState({ err: '', succ: '' });

  const onClickLeaveComment = () => {
    setIsShowForm(!isShowForm);
  };

  const onChangeTextValue = e => {
    const { name, value } = e.target;
    setMessage({});
    setFormData({ ...formData, [name]: value });
  };

  const onChangeCheckbox = e => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const onSubmitComment = () => {
    const validationMsg = validForm(formData);
    if (validationMsg) {
      setMessage({ err: validationMsg });
      return;
    }

    if (formData.storeName) {
      setStorage(
        '__commentAuthor',
        JSON.stringify({
          author_name: formData.author_name,
          author_email: formData.author_email,
          storeName: formData.storeName
        })
      );
    } else {
      removeStorage('__commentAuthor');
    }

    const data = JSON.stringify({
      post: postDatabaseId,
      author_name: formData.author_name,
      author_email: formData.author_email,
      content: formData.content
    });

    wpApi('comments', 'POST', data)
      .then(({ response, status }) => {
        if (response) {
          setMessage({ succ: 'Your comment is awaiting moderation.' });
          setFormData({});
        } else if (status === 401) {
          setMessage({ err: 'Sorry, you must be logged in to comment.' });
        } else {
          setMessage({
            err: 'Sorry, something went wrong. Try again later.'
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleShowMore = () => {
    setShowAll(!showAll);
  };

  const [items, hasShowMore] = useMemo(() => {
    if (isEmpty(comments)) {
      return [[], false];
    }
    const showButton = comments.length > 3;
    if (showAll || !showButton) {
      return [comments, showButton];
    }
    return [comments.slice(0, 3), showButton];
  }, [comments, showAll]);

  useEffect(() => {
    let userEmail = null;
    let userName = null;
    let storeName = false;

    try {
      const stored = JSON.parse(getStorage('__commentAuthor'));
      storeName = stored.storeName;
      userEmail = stored.author_email;
      userName = stored.author_name;
    } catch (error) {
      // console.warn('invalid');
    }

    if ('state' in authCtx && 'object' in authCtx.state) {
      const {
        state: { object: userObj }
      } = authCtx;
      const { email, first_name, last_name } = userObj;
      userEmail = email;
      userName = `${first_name} ${last_name}`;
    }

    setFormData({
      ...formData,
      storeName,
      author_email: userEmail,
      author_name: userName
    });
    // eslint-disable-next-line
  }, [authCtx]);

  return (
    <section className={styles.root}>
      <div className={styles.divider}>
        <Divider shape="cloud" />
      </div>
      <div className={styles.background}>
        <div className={styles.container}>
          <div className={styles.sectionHeader}>
            <h5>Comments</h5>
            <div
              className={styles.leaveComment}
              onClick={onClickLeaveComment}
              role="presentation"
            >
              <Icon symbol="addReview" />
              <span>Leave a comment</span>
            </div>
          </div>
          {isShowForm && (
            <div className={styles.commentForm}>
              {msg.succ && <p className={styles.success}>{msg.succ}</p>}
              {msg.err && <p className={styles.error}>{msg.err}</p>}
              <div className="formField">
                <label htmlFor="content">Comment</label>
                <textarea
                  required
                  rows={4}
                  name="content"
                  value={formData.content || ''}
                  onChange={onChangeTextValue}
                />
              </div>
              <div className="grid grid-50">
                <div className="formField">
                  <label htmlFor="author_name">Name</label>
                  <input
                    required
                    type="text"
                    name="author_name"
                    value={formData.author_name || ''}
                    onChange={onChangeTextValue}
                  />
                </div>
                <div className="formField">
                  <label htmlFor="author_email">Email</label>
                  <input
                    required
                    type="text"
                    name="author_email"
                    value={formData.author_email || ''}
                    onChange={onChangeTextValue}
                  />
                </div>
              </div>
              <div className="formField">
                <Checkbox
                  name="storeName"
                  value={!!formData.storeName}
                  label="Save my name and email in this browser for the next time I comment."
                  action={onChangeCheckbox}
                  isChecked={!!formData.storeName}
                />
              </div>
              <div className="formField">
                <Button
                  type="inputButton"
                  level="primary"
                  value="Post comment"
                  onClick={onSubmitComment}
                />
              </div>
            </div>
          )}
          <div className={styles.comments}>
            {items.map(comment => (
              <CommentCard key={comment.id} comment={comment} />
            ))}
            {isEmpty(comments) && (
              <div className={styles.noComment}>
                <span>No comments yet. Be the first!</span>
              </div>
            )}
          </div>
          {hasShowMore && (
            <div className={styles.sectionFooter}>
              <Button type="button" level="primary" onClick={handleShowMore}>
                {showAll ? 'Less' : 'See more'}
              </Button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default CommentList;
