import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { transformWpPost } from '../../../../helpers/wordpress';
import Output from '../Output';

const sectionQuery = graphql`
  query AllWpRecentPosts {
    posts: allWpPost(limit: 6, sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        excerpt
        content
        uri
        author {
          node {
            name
            uri
            avatar {
              url
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          node {
            uri
            sourceUrl
          }
        }
        tags {
          nodes {
            uri
            name
          }
        }
        categories {
          nodes {
            name
            uri
          }
        }
        comments {
          nodes {
            id
            author {
              node {
                name
                id
                url
              }
            }
            content
          }
        }
        databaseId
      }
    }
  }
`;

const All = ({ hideBg, title }) => {
    return (
        <StaticQuery
            query={sectionQuery}
            render={({ posts }) => {
                const recentPosts = posts?.nodes?.map(post => transformWpPost(post)) || [];
                return <Output hideBg={hideBg} title={title} recentPosts={recentPosts} />
            }}
        />
    );
};

export default All;
