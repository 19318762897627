import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import SocialShare from '../../molecules/SocialShare/SocialShare';
import { readTime } from '../../../helpers/general';
import abkapostImage from './abkapost.png';

import styles from './PostCard.module.css';

const PostCard = ({
  title,
  excerpt,
  content,
  thumbnail,
  slug,
  viewMode,
  date,
  size,
  categories,
  titleOnly
}) => {
  const isGridView = viewMode === 'grid';
  // single mode is for the post page
  const isSingle = viewMode === 'single';
  const excerptStr = useMemo(() => {
    const str = excerpt || '';

    return str
      .replace(/<a class="more-link" (.*?)>(.*?)<\/a>/g, '')
      .replace(/<p>(.*?)<\/p>/g, '$1')
      .replace(/\[...\]/g, '... ');
  }, [excerpt]);

  const classes = useMemo(() => {
    const initClass = [styles.postCard];
    if (viewMode === 'grid') {
      initClass.push(styles.gridView);
    } else if (isSingle) {
      initClass.push(styles.singleView);
    } else {
      initClass.push(styles.listView);
    }
    if (size) {
      initClass.push(styles[size]);
    }
    return initClass.join(' ');
  }, [viewMode, size, isSingle]);

  const timeToRead = useMemo(() => {
    if (content) {
      return readTime(content);
    }
    return 0;
  }, [content]);

  const categoriesTags = useMemo(() => {
    if (categories) {
      return categories.map((cat, catIdx) => (
        <Link to={cat.uri} key={catIdx}>
          {cat.name}
        </Link>
      ));
    }
    return [];
  }, [categories]);

  const thumbnailUrl = useMemo(() => {
    if (thumbnail) {
      return thumbnail.replace('http:', 'https:');
    }

    return thumbnail;
  }, [thumbnail])

  return (
    <div className={classes}>
      {isSingle && <div className={styles.topCategories}>{categoriesTags}</div>}
      <Link to={slug}>
        <div className={styles.postImageContainer}>
          <img
            loading="lazy"
            className={styles.postImage}
            src={thumbnailUrl || abkapostImage}
            alt={title}
          />
        </div>
      </Link>
      <div className={styles.postBody}>
        <div className={styles.postTitle}>
          {slug && (
            <Link to={slug}>
              <h1 className="h6">{title}</h1>
            </Link>
          )}
          {!slug && <h6>{title}</h6>}
          {!titleOnly && (
            <div className={styles.postDate}>
              {categories && (
                <div className={styles.categories}>{categoriesTags}</div>
              )}
              {isSingle ? (
                <span>
                  {date} - {timeToRead} min Read
                </span>
              ) : (
                <span>{timeToRead} min Read</span>
              )}
            </div>
          )}
        </div>
        {excerptStr && !isGridView && (
          <div>
            <span
              className={styles.postContent}
              dangerouslySetInnerHTML={{
                __html: excerptStr
              }}
            />
            <span className={styles.readMore}>
              {slug && <Link to={slug}>read more</Link>}
            </span>
          </div>
        )}
        {content && isSingle && (
          <div
            className={styles.postContent}
            dangerouslySetInnerHTML={{
              __html: content
            }}
          />
        )}
      </div>
      {isSingle && (
        <div className={styles.socials}>
          <SocialShare label="share" imageUrl={thumbnailUrl || abkapostImage} />
        </div>
      )}
    </div>
  );
};

PostCard.propTypes = {
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string,
  content: PropTypes.string,
  thumbnail: PropTypes.string,
  slug: PropTypes.string,
  authorName: PropTypes.string,
  authorUri: PropTypes.string,
  date: PropTypes.string,
  viewMode: PropTypes.string,
  size: PropTypes.string
};

PostCard.defaultProps = {
  excerpt: '',
  content: '',
  size: 'medium'
};

export default PostCard;
