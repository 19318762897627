import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/organisms/Layout/Layout';
import PostOne from '../components/organisms/PostOne/PostOne';
import SEO from '../components/organisms/Seo/Seo';
import PageBanner from '../components/molecules/BlogPageBanner/BlogPageBanner';
import CommentList from '../components/organisms/CommentList/CommentList';
import RecentPosts from '../components/organisms/RecentPosts/RecentPosts';
import { transformWpPost } from '../helpers/wordpress';

const BlogPost = ({ data }) => {
  const { wpPost: post } = data;
  const transformedPost = transformWpPost(post);
  const { comments } = transformedPost;
  const categorySlug = post.categories?.nodes?.length > 0 ? post.categories.nodes[0].slug : 'all';
  const postMeta = [
    {
      property: `og:image`,
      content: post.featuredImage?.node.sourceUrl || '',
    },
    {
      property: `twitter:image`,
      content: post.featuredImage?.node.sourceUrl || '',
    },
  ];

  return (
    <Layout>
      <SEO title={`${transformedPost.title} | A Better Kinder Awesome`} meta={postMeta}>
        <body className="noFooterMargin" />
      </SEO>
      <PageBanner imageOnly />
      <PostOne post={transformedPost} />
      <CommentList comments={comments} postDatabaseId={post.databaseId} />
      <RecentPosts title="You may also like" category={categorySlug} />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    wpPost(id: { eq: $id }) {
      ...PostListFields
    }
  }
`;
