import React, { useMemo, useState } from 'react';
import styles from './CommentCart.module.css';

const CommentCard = ({ comment }) => {
  const { content, author = {}, date } = comment || {};
  const [showFullText, setShowFullText] = useState(false);

  const handleClick = () => {
    setShowFullText(!showFullText);
  };

  const [text, hasMore] = useMemo(() => {
    const contentText = content?.replace(/(<([^>]+)>)/gi, '');
    const showButton = contentText?.length > 400;
    if (showFullText || !showButton) {
      return [content, showButton];
    }
    return [`<p>${contentText.slice(0, 400)}...</p>`, showButton];
  }, [showFullText, content]);

  return (
    <div className={styles.commentCard}>
      {/* <div className={styles.starsContainer}>
        <Stars stars={4.5} />
      </div> */}
      <div className={styles.authorName}>{author.name}</div>
      <div className={styles.postDate}>{date}</div>
      {/* <div className={styles.commentTitle}>
        <h6>Title</h6>
      </div> */}
      <div
        className={styles.commentCardContent}
        dangerouslySetInnerHTML={{
          __html: text || ''
        }}
      />
      {hasMore && (
        <span
          data-button
          role="presentation"
          onClick={handleClick}
          className={styles.readMore}
        >
          {showFullText ? 'Less' : 'Read more'}
        </span>
      )}
    </div>
  );
};

export default CommentCard;
