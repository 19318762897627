import React from 'react';
import PostCard from '../../molecules/PostCard/PostCard';
import Slider from '../../atoms/Slider/Slider';
import Container from '../../atoms/Container/Container';
import styles from './RecentPosts.module.css';

const sliderProps = {
  lazyLoad: true,
  autoplaySpeed: 4000,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  dots: false,
  responsive: [
    {
      breakpoint: 880,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 551,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const Output = ({ hideBg, title, recentPosts }) => {
    const classes = [styles.container];
    if (!hideBg) {
        classes.push(styles.background);
    }

    classes.push('recent-posts-slider');    
    const classContainer = classes.join(' ');

    const ContainerComp = hideBg ? React.Fragment : Container;
  
    return (
        <section className={styles.root}>
            <div className={classContainer}>
                <ContainerComp>
                    {!hideBg && <h4 className="text-center">{title}</h4>}
                    <div className={styles.sliderContainer}>
                        <Slider {...sliderProps}>
                        {(recentPosts || []).map((post, postIndex) => (
                            <div key={postIndex} className={styles.slideCard}>
                            <PostCard
                                size="large"
                                viewMode="grid"
                                titleOnly
                                key={post.id}
                                title={post.title}
                                excerpt={post.excerpt}
                                content={post.content}
                                categories={post.categories}
                                slug={post.slug}
                                authorName={post.author_name}
                                authorUri={post.author_uri}
                                date={post.published_date}
                                thumbnail={post.thumbnail_path}
                            />
                            </div>
                        ))}
                        </Slider>
                    </div>
                </ContainerComp>
            </div>
        </section>
    );
};

export default Output;
