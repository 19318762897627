import React from 'react';
import PropTypes from 'prop-types';
import Container from '../../atoms/Container/Container';
import PostCard from '../../molecules/PostCard/PostCard';
import styles from './PostOne.module.css';

const PostOne = ({ post }) => {
  return (
    <Container>
      <div className={styles.blog}>
        <PostCard
          title={post.title}
          slug={post.slug}
          authorName={post.author_name}
          authorUri={post.author_uri}
          date={post.published_date}
          content={post.content}
          categories={post.categories || []}
          thumbnail={post.thumbnail_path}
          viewMode="single"
        />
      </div>
    </Container>
  );
};

PostOne.propTypes = {
  post: PropTypes.shape({}).isRequired
};

export default PostOne;
