import React from 'react';
import AllPosts from './filtered/All';
import AwesomeThingsPosts from './filtered/AwesomeThings';
import BetterThingsPosts from './filtered/BetterThings';
import KinderThingsPosts from './filtered/KinderThings';

const RecentPosts = ({ hideBg, title, category = 'all' }) => {
  if (category === 'awesome-things') {
    return <AwesomeThingsPosts hideBg={hideBg} title={title} />
  } else if (category === 'better-things') {
    return <BetterThingsPosts hideBg={hideBg} title={title} />
  } else if (category === 'kinder-things') {
    return <KinderThingsPosts hideBg={hideBg} title={title} />
  } else {
    return <AllPosts hideBg={hideBg} title={title} />
  }
};

export default RecentPosts;
