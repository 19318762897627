import { get } from 'lodash';
/**
 * Wordpress API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} endpoint       The BC API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST, PUT, DELETE. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * @param   {Number} version        (Optional) Version of the API to hit. 2 is assumed
 * 
 * @return  {Object}                {response, status}
 *
    import { wpApi } from '../helpers/wordpress'

    wpApi('endpoint', 'POST', bodyObject, version).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
async function wpApi(endpoint, method, body, version) {
  const options = {
    method: method ? method : 'GET',
    credentials: 'same-origin',
    mode: 'same-origin'
  };

  if (body) {
    let bodyString = body;
    if (typeof body === 'object') {
      bodyString = JSON.stringify(body);
    }

    options.body = bodyString;
  }

  const parseJson = async response => {
    const text = await response.text();
    try {
      const json = JSON.parse(text);
      return json;
    } catch (err) {
      return text;
    }
  };

  return await fetch(
    `/.netlify/functions/wordpress?${
      version ? `v=${version}&` : ''
    }endpoint=${btoa(endpoint)}`,
    options
  ).then(async res => ({ response: await parseJson(res), status: res.status }));
}

function transformWpPost(post) {
  const wpComments = get(post, 'comments.nodes', []).flatMap(node => {
    const author = node.author.node;
    return { ...node, author };
  });

  return {
    id: post.id,
    databaseId: post.databaseId,
    comments: wpComments,
    title: post.title,
    excerpt: post.excerpt,
    slug: `/abetterkinderawesome${post.uri}`,
    content: post.content,
    author_name: post.author.node.name,
    author_uri: post.author.node.uri,
    published_date: post.date,
    tags: post.tags.nodes,
    categories: (post.categories.nodes || []).map(cat => ({
      ...cat,
      uri: `/abetterkinderawesome${cat.uri}`
    })),
    thumbnail_path: get(post, ['featuredImage', 'node', 'sourceUrl'])
  };
}

export { wpApi, transformWpPost };
