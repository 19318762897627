import React from 'react';
import PageBanner from '../../atoms/PageBanner/PageBanner';
import IndividualContentBlock from "../../atoms/IndividualContentBlock/IndividualContentBlock"
// import styles from './BlogPageBanner.module.css';

const BlogPageBanner = ({ breadcrumb, title, imageOnly }) => {
  return (
    <PageBanner
      breadcrumb={breadcrumb}
      title={title}
      color="purple"
      imageSize="small"
      imageName="akab"
      imageOnly={imageOnly}
    >
      <IndividualContentBlock sectionKey="Blog landing page header" />
    </PageBanner>
  );
};

export default BlogPageBanner;
